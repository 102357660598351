import React from 'react';

import { Hero } from 'src/components/organisms/Hero';
import { Button } from 'src/components/atoms/Button';
import { Link } from 'src/containers/Link';
import { Video } from 'src/components/atoms/Video';

/*
  image
  ctas
  customData
    flags
      alternative: boolean
      primary?: boolean;
      wavey?: boolean; (exclusive)
      sloped?: boolean; (exclusive)
      width?: 'large' | 'xlarge';
      subtitle?: ReactNode;
      subtitleWidth?: 'small' | 'medium' | 'large' | 'xlarge' | 'full';
 */

export default function LegacyHeader({ heading, content, video, ctas, customData }) {
  customData = JSON.parse(customData || {});
  return (
    <Hero
      title={heading}
      subtitle={content}
      category={null}
      actions={
        ctas.length ? (
          <>
            <Button to={ctas[0].url} aria-label={ctas[0].a11yText || ctas[0].text}>
              {ctas[0].text}
            </Button>
            {ctas.length > 1 && (
              <>
                <br />
                <Link
                  to={ctas[1].url}
                  style={{
                    display: 'inline-block',
                    color: 'inherit',
                    fontSize: '15px',
                    textDecoration: 'underline',
                  }}
                >
                  {ctas[1].text}
                </Link>
              </>
            )}
          </>
        ) : null
      }
      image={
        video && (
          <Video
            src={video.url}
            width="900"
            height="62.4%"
            autoPlay
            muted
            playsInline
            controls
            loop
            shadow
            round
          />
        )
      }
      centered
      {...(customData.flags || {})}
    />
  );
}
